.App {
    min-height: 100vh;
    width: 100%;
}

.connection-bar {
    position: absolute;
    width: 100%;

    background-color: #E74C3C;
    color: white;

    font-size: 20px;
    text-align: center;
    padding: 10px 0;
}

.reconnect-form {
    display: inline-block;
    margin-left: 10px;
}

.reconnect-form input[type="submit"] {
    background-color: white;
    color: #E74C3C;

    font-size: 15px;
    padding: 0 10px;
}
