.landing-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100vh;
}

.landing {
    padding: 30px;
    border-radius: 10px;

    background-color: white;
    color: #9B59B6;

    max-width: 600px;

    display: inline-block;
}

.landing .message {
    transition: color 200ms linear;
}

.landing .error {
    color: #FF7979;
}

.landing .success {
    color: #27ae60;
}

.landing h2 {
    font-size: 48px;
    font-family: 'Montserrat SemiBold';
}

.landing input[type="text"] {
    width: 100%;
    border: 0;
    border-bottom: 6px solid #9B59B6;

    color: #9B59B6;

    font-size: 40px;
    font-family: 'Inconsolata';

    margin: 20px 0;
}

.landing input[type="text"]::placeholder {
    color: #9B59B6;
    opacity: 0.5;
}

.enter-room {
    margin-top: 30px;
    display: none;
}

.enter-room.show {
    display: block;
}

.enter-room .join-room {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.enter-room .join-room input[type="text"] {
    width: 80%;
}

.enter-room .create-room {
    text-align: center;
}

.enter-room .create-room input[type="submit"] {
    width: 80%;
}

.enter-room input[type="submit"] {
    border: 0;
    border-radius: 5px;

    background-color: #9B59B6;
    color: white;

    box-shadow: rgba(0, 0, 0, 0.25) 0 0 4px;

    cursor: pointer;

    font-family: 'Montserrat SemiBold';
    font-size: 15px;

    padding: 15px;
}

input[type="submit"]:disabled {
    background-color: #cccccc;
    color: #666666;
    pointer-events: none;
}

.enter-room hr {
    padding: 0;
    border: none;
    border-top: 1px solid #9B59B6;
    text-align: center;

    overflow: visible;

    margin: 20px 0;
}

.enter-room hr:after {
    content: 'or';
    font-family: 'Montserrat SemiBold';
    display: inline-block;
    background: #fff;
    position: relative;

    color: #9B59B6;

    width: 60px;
    font-size: 25px;
    transform: translateY(-50%);
}

.enter-room .error {
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
}
