body {
  margin: 0;
  font-family: 'Inconsolata';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #9B59B6;
}

* {
    margin: 0;
}

.hide {
    display: none !important;
}

.invisible {
    visibility: hidden;
}

input {
    outline: none;
}
